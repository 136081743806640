export const GET_STORES_REQUEST = "GET_STORES_REQUEST";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAIL = "GET_STORES_FAIL";

export const GET_STORE_BY_ID_REQUEST = "GET_STORE_BY_ID_REQUEST";
export const GET_STORE_BY_ID_SUCCESS = "GET_STORE_BY_ID_SUCCESS";
export const GET_STORE_BY_ID_FAIL = "GET_STORE_BY_ID_FAIL";

export const UPDATE_STORE_BY_ID_REQUEST = "UPDATE_STORE_BY_ID_REQUEST";
export const UPDATE_STORE_BY_ID_SUCCESS = "UPDATE_STORE_BY_ID_SUCCESS";
export const UPDATE_STORE_BY_ID_FAIL = "UPDATE_STORE_BY_ID_FAIL";

export const CREATE_STORE_REQUEST = "CREATE_STORE_REQUEST";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_FAIL = "CREATE_STORE_FAIL";

export const DELETE_STORE_REQUEST = "DELETE_STORE_REQUEST";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL";
