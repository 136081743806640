import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import "../css/dealer.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores } from "../actions/storeActions";

const StoresScreen = () => {
  const dispatch = useDispatch();

  const getStores = useSelector((state) => state.getStores);
  const { stores } = getStores;
  // let distinctStates = [...new Set(stores.map((store) => store.state))];
  useEffect(() => {
    dispatch(getAllStores());
  }, [dispatch]);
  const navigate = useNavigate();
  const [inputState, setInputState] = useState("All");
  const [filteredStores, setFilteredStores] = useState(stores);

  const handleSelectChange = (e) => {
    setInputState(e.target.value);
  };

  const handleSearchClick = () => {
    const newFilteredStores =
      inputState === "All" || inputState === ""
        ? stores
        : stores.filter((store) => store.state === inputState);
    setFilteredStores(newFilteredStores);
  };

  return (
    <div className="container-fluid ">
      <div className="row ">
        <div className="col-lg-2 p-0">
          <Sidebar active={5} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div className="mt-3 p-3">
            <div className="d-flex align-items-center justify-content-between pe-3">
              <h1 className="mb-3">Stores</h1>
              <div className="d-flex mb-3  align-items-center">
                <div className="form-floating  me-3" style={{ width: "250px" }}>
                  <select
                    class="form-control"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    onChange={handleSelectChange}
                  >
                    <option selected value="All">
                      All
                    </option>
                    {stores &&
                      [...new Set(stores.map((store) => store.state))]
                        .sort()
                        .map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                  </select>
                  <label for="floatingSelect" className="text-grey">
                    Select State
                  </label>
                </div>
                <button
                  onClick={handleSearchClick}
                  className="btn  btn-primary text-light"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
                <button className="btn ms-3 btn-success text-light">
                  <i class="fa-solid fa-download"></i>
                </button>
                <Link to={"/add-store"}>
                  <button className="btn ms-3 btn-primary ">
                    <i class="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </div>
            </div>
            <div className="grid-container">
              {filteredStores &&
                filteredStores.map((store) => (
                  <div className=" card dealer-card">
                    <div className="mx-auto">
                      <button
                        className="btn btn-primary m-0"
                        onClick={() => navigate(`/store/${store._id}`)}
                      >
                        EDIT
                      </button>
                    </div>
                    <div className="text-light">
                      <div className="card-body">
                        <h5 className="card-title mb-3">{store.name}</h5>

                        <div className="align-items-center m-0 mb-2 d-flex">
                          <i class="fa-solid fa-phone me-4"></i>
                          <p className="card-text m-0 mt-1 ">
                            {store.phoneNumber ? store.phoneNumber : "NA"}
                          </p>
                        </div>
                        {/* <div className="align-items-center m-0 mb-2 d-flex">
                          <i class="fa-solid fa-envelope me-4"></i>
                          <p className="card-text m-0 mt-1 ">
                            {dealer.emailID ? dealer.emailID : "NA"}
                          </p>
                        </div> */}
                        <a
                          href={store.link}
                          className="text-decoration-none"
                          style={{ color: "inherit" }}
                        >
                          <div className="align-items-center m-0 mb-2 d-flex">
                            <i class="fa-solid fa-location-dot me-4"></i>
                            <p className="card-text m-0 mt-1 ">
                              {store.city ? store.city : "NA"}, &nbsp;
                              {store.state ? store.state : "NA"}
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoresScreen;
