import axios from "axios";
import {
  CREATE_STORE_FAIL,
  CREATE_STORE_REQUEST,
  DELETE_STORE_FAIL,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  GET_STORES_FAIL,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORE_BY_ID_FAIL,
  GET_STORE_BY_ID_REQUEST,
  GET_STORE_BY_ID_SUCCESS,
  UPDATE_STORE_BY_ID_FAIL,
  UPDATE_STORE_BY_ID_REQUEST,
  UPDATE_STORE_BY_ID_SUCCESS,
} from "../constants/storeConstants";
import { CREATE_PRODUCT_SUCCESS } from "../constants/productConstants";

export const getAllStores = () => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: GET_STORES_REQUEST });
    const { data } = await axios.get("/api/store", config);
    dispatch({ type: GET_STORES_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_STORES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const storeById = (id) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: GET_STORE_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/store/${id}`, config);
    dispatch({ type: GET_STORE_BY_ID_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_STORE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateStore =
  (
    id,
    name,
    phoneNumber,
    storeId,
    address,
    city,
    state,
    link,
    hours,
    latitude,
    longitude
  ) =>
  async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      dispatch({ type: UPDATE_STORE_BY_ID_REQUEST });
      const { data } = await axios.put(
        `/api/store`,
        {
          id,
          name,
          phoneNumber,
          storeId,
          address,
          city,
          state,
          link,
          hours,
          latitude,
          longitude,
        },
        config
      );
      dispatch({
        type: UPDATE_STORE_BY_ID_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_STORE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteStore = (id) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: DELETE_STORE_REQUEST });
    const { data } = await axios.delete(`/api/store/${id}`, config);

    dispatch({
      type: DELETE_STORE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_STORE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createStore =
  (
    name,
    phoneNumber,
    storeId,
    address,
    city,
    state,
    link,
    hours,
    latitude,
    longitude,
    ebo
  ) =>
  async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      dispatch({ type: CREATE_STORE_REQUEST });
      const { data } = await axios.post(
        "/api/store",
        {
          name,
          phoneNumber,
          storeId,
          address,
          city,
          state,
          link,
          hours,
          latitude,
          longitude,
          ebo,
        },
        config
      );
      dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: CREATE_STORE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
