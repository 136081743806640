import React, { useEffect, useState } from "react"
import Sidebar from "../components/Sidebar"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import axios from "axios"
import { useParams } from "react-router-dom"

const PrebookingDetailsScreen = () => {
	const { id } = useParams()

	const adminLogin = useSelector(
		(state) => state.adminLogin,
	)
	const { admin } = adminLogin

	const [bookingDetails, setBookingsDetails] =
		useState()

	useEffect(() => {
		const getPrebookingDetails = async () => {
			try {
				const config = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${admin.token}`,
					},
				}

				const { data } = await axios.get(
					`/api/form/preorders/${id}`,

					config,
				)
				if (data) {
					setBookingsDetails(data)
				}
			} catch (error) {}
		}

		getPrebookingDetails()
	}, [])

	const renderBike = (bikeName, bikeColor) => {
		switch (bikeName && bikeColor) {
			case "X1" && "Deep Blue":
				return (
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/B4.png'
						alt=''
						srcset=''
						className='img-fluid'
					/>
				)
			case "X1" && "Traffic Yellow":
				return (
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/Y4.png'
						alt=''
						srcset=''
						className='img-fluid'
					/>
				)
			case "X2" && "Warm Red":
				return (
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/R4.png'
						alt=''
						srcset=''
						className='img-fluid'
					/>
				)
			case "X2" && "Sky Blue":
				return (
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/4.png'
						alt=''
						srcset=''
						className='img-fluid'
					/>
				)
			case "X3" && "Champagne Gold":
				return (
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/G4.png'
						alt=''
						srcset=''
						className='img-fluid'
					/>
				)
			case "X3" && "Polished Silver":
				return (
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/S4.png'
						alt=''
						srcset=''
						className='img-fluid'
					/>
				)
			default:
				return null
		}
	}

	const currencyFormat = (num) => {
		return (
			"Rs" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	const getISTTime = (time) => {
		var localTime = new Date(time).toLocaleString(
			undefined,
			{
				timeZone: "Asia/Kolkata",
			},
		)
		return localTime
	}

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={13} />
				</div>
				<div
					className='col-lg-10 p-0'
					style={{
						height: "100vh",
						overflowY: "scroll",
					}}>
					{bookingDetails && (
						<>
							<div className='d-flex align-items-center justify-content-between'>
								<div className='mt-3  p-3'>
									<span className='  d-block'>
										Ordered on
									</span>
									<h3 className={`fw-bold `}>
										{getISTTime(
											bookingDetails.order
												.updatedAt,
										)}
									</h3>
								</div>

								<div className='me-4'>
									<span className=''>
										Payment Status
									</span>
									{bookingDetails.order
										.paymentStatus ? (
										<h3
											className=''
											style={{
												color: "#10b068",
												fontWeight: "900",
											}}>
											Paid
										</h3>
									) : (
										<h3
											style={{
												color: "red",
												fontWeight: "900",
											}}>
											Unpaid
										</h3>
									)}
								</div>
							</div>

							<div className='container-fluid'>
								<div className='row mt-3'>
									<div className='col-12 col-md-6  '>
										<div
											className='bg-light  p-4'
											style={{
												borderRadius: "10px",
											}}>
											<h6 className='mb-2'>
												Customer Details:
											</h6>
											<hr />
											<p>
												name :{" "}
												<span className='ms-3'>
													{" "}
													{
														bookingDetails.order
															.name
													}
												</span>
											</p>
											<p>
												email :{" "}
												<span className='ms-3'>
													{
														bookingDetails.order
															.email
													}
												</span>
											</p>
											<p>
												Phone Number :{" "}
												<span className='ms-3'>
													{
														bookingDetails.order
															.phoneNumber
													}
												</span>
											</p>
										</div>
										<div className=' mt-4'>
											<div
												className='bg-light p-4'
												style={{
													borderRadius: "10px",
												}}>
												<h6 className='mb-2'>
													Shipping Details:
												</h6>
												<hr />
												<div className='d-flex'>
													<p className='col-2'>
														Address :{" "}
													</p>{" "}
													<p>
														{
															bookingDetails.order
																.address
														}
													</p>
												</div>
											</div>
										</div>
										<div
											className='d-flex pe-3'
											style={{ gap: "1rem" }}>
											<div
												className=' col-6 p-4 mt-4 bg-light'
												style={{
													borderRadius: "10px",
												}}>
												<h6 className='mb-2'>
													Bike Details:
												</h6>
												<hr />
												{bookingDetails.order && (
													<>
														<p className='d-flex justify-content-between pe-3'>
															Bike Name:
															<span className='fw-normal'>
																{
																	bookingDetails
																		.order
																		.bikeInfo
																		.bikeName
																}
															</span>
														</p>

														<p className='d-flex justify-content-between pe-3'>
															Color:
															<span className='fw-normal'>
																{
																	bookingDetails
																		.order
																		.bikeInfo
																		.color
																}
															</span>
														</p>
													</>
												)}
											</div>
											<div
												className=' col-6 p-4 mt-4 bg-light'
												style={{
													borderRadius: "10px",
												}}>
												<h6 className='mb-2'>
													Razorpay Details:
												</h6>
												<hr />
												{bookingDetails.order
													.paymentStatus && (
													<>
														<p className='d-flex justify-content-between pe-3'>
															Payment ID:
															<span className='fw-normal'>
																{
																	bookingDetails
																		.paymentInfo
																		.id
																}
															</span>
														</p>
														<p className='d-flex justify-content-between pe-3'>
															Amount Paid:
															<span className='fw-normal'>
																Rs{" "}
																{String(
																	bookingDetails
																		.paymentInfo
																		.amount,
																).substring(
																	0,
																	String(
																		bookingDetails
																			.paymentInfo
																			.amount,
																	).length - 2,
																)}
															</span>
														</p>
														<p className='d-flex justify-content-between pe-3'>
															Status:
															<span className='fw-normal'>
																{
																	bookingDetails
																		.paymentInfo
																		.status
																}
															</span>
														</p>
														<p className='d-flex justify-content-between pe-3'>
															Method:
															<span className='fw-normal text-uppercase'>
																{
																	bookingDetails
																		.paymentInfo
																		.method
																}
															</span>
														</p>
													</>
												)}
											</div>
										</div>
									</div>

									<div className='col-12 col-md-6  '>
										<div
											className='bg-light '
											style={{
												borderRadius: "10px",
											}}>
											{renderBike(
												bookingDetails.order
													.bikeInfo.bikeName,
												bookingDetails.order
													.bikeInfo.color,
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default PrebookingDetailsScreen
