import {
  CREATE_STORE_FAIL,
  CREATE_STORE_REQUEST,
  CREATE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  GET_STORES_FAIL,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORE_BY_ID_FAIL,
  GET_STORE_BY_ID_REQUEST,
  GET_STORE_BY_ID_SUCCESS,
  UPDATE_STORE_BY_ID_FAIL,
  UPDATE_STORE_BY_ID_REQUEST,
  UPDATE_STORE_BY_ID_SUCCESS,
} from "../constants/storeConstants";

export const getStoresReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STORES_REQUEST:
      return { loading: true };
    case GET_STORES_SUCCESS:
      return { loading: false, stores: action.payload };
    case GET_STORES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getStoreByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STORE_BY_ID_REQUEST:
      return { loading: true };
    case GET_STORE_BY_ID_SUCCESS:
      return { loading: false, store: action.payload };
    case GET_STORE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateStoreByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_STORE_BY_ID_REQUEST:
      return { loading: true };
    case UPDATE_STORE_BY_ID_SUCCESS:
      return { loading: false, updatedStore: action.payload };
    case UPDATE_STORE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STORE_REQUEST:
      return { loading: true };
    case CREATE_STORE_SUCCESS:
      return { loading: false, createdStore: action.payload };
    case CREATE_STORE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORE_REQUEST:
      return { loading: true };
    case DELETE_STORE_SUCCESS:
      return { loading: false, success: true };
    case DELETE_STORE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
