import React, { useEffect } from "react";
import "../css/sidebar.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Sidebar = ({ active }) => {
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;

  const adminInfo = useSelector((state) => state.adminInfo);
  const { adminData } = adminInfo;

  useEffect(() => {
    if (admin) {
      if (admin.token) {
        if (!adminData) {
          navigate("/login");
        }
      }
    } else {
      navigate("/login");
    }
  }, [admin, adminData]);

  return (
    <div
      className="sidebar   text-light "
      style={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div className="mt-0 pt-3 ">
        <img
          src="../images/white.png"
          width="170"
          className=" d-block ps-3 img-fluid d-block   "
          alt=""
        />
      </div>

      <div className="sidebar-links p-2 pt-0 mt-4 ">
        <Link
          to={"/"}
          className={`d-flex text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 1 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-earth-americas"></i>
          <span>Overview</span>
        </Link>
        <Link
          to={"/orders"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 2 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-cart-flatbed"></i>
          <span>Orders</span>
        </Link>
        <Link
          to={"/product"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3 mb-3   p-3 ${
            active === 3 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3  fa-box-open"></i>
          <span>Products</span>
        </Link>
        <Link
          to={"/addproduct"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 4 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-cart-plus"></i>
          <span>Add Product</span>
        </Link>
        <Link
          to={"/store"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3 mb-3   p-3 ${
            active === 5 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-store"></i>
          <span>Stores</span>
        </Link>
        <Link
          to={"/add-store"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 6 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-cart-plus"></i>
          <span>Add Store</span>
        </Link>
        {/* <Link
          to={"/prebookings"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 11 && "active-link"
          }`}
        >
          <i class="pt-1 me-3 fa-sharp fa-solid fa-file-invoice"></i>
          <span>Prebooking</span>
        </Link> */}
        {/* <Link
          to={"/notifyme"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 12 && "active-link"
          }`}
        >
          <i class="pt-1 me-3 fa-solid fa-bell"></i>
          <span>Notify me</span>
        </Link> */}
        <hr className="mb-1" />

        <Link
          to={"/testride"}
          className={`d-flex  text-decoration-none text-light sidebar-item   p-3 ${
            active === 7 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-person-biking"></i>
          <span>TestRide</span>
        </Link>

        <Link
          to={"/warranty"}
          className={`d-flex text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 8 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3  fa-gears"></i>
          <span>Warranty</span>
        </Link>
        <Link
          to={"/insurance"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 9 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-shield-halved"></i>
          <span>Insurance</span>
        </Link>
        <Link
          to={"/partner-with-us"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 10 && "active-link"
          }`}
        >
          <i class=" pt-1 me-3 fa-solid fa-handshake"></i>
          <span>Partner</span>
        </Link>
        <Link
          to={"/contact"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 11 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-envelope"></i>
          <span>Contact Us</span>
        </Link>

        <hr className="" />
        <Link
          to={"/promocode"}
          className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
            active === 12 && "active-link"
          }`}
        >
          <i class="fa-solid pt-1 me-3 fa-tags"></i>
          <span>Promocodes</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
