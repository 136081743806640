import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "../actions/productActions";
import { Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
const ProductAddScreen = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [productType, setProductType] = useState("");
  const [price, setPrice] = useState(0);
  const [slashPrice, setSlashPrice] = useState(0);
  const [gst, setGst] = useState(0);
  const [withoutGST, setWithoutGST] = useState(0);
  const [inStock, setInStock] = useState(0);
  const [category, setCategory] = useState("");
  const [specs, setSpecs] = useState([]);

  const productCreate = useSelector((state) => state.createProduct);
  const { createdProduct } = productCreate;

  useEffect(() => {
    if (createdProduct) {
      // Redirect to the product details page or the product list page
      Navigate(`/product/${createProduct._id}`);
    }
  }, [createdProduct]);

  const adminInfo = useSelector((state) => state.adminInfo);
  const { adminData } = adminInfo;

  useEffect(() => {
    if (adminData) {
      if (adminData.accessLevel === "Viewer") {
        console.log("you're not allowed");
      }
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProduct(
        name,
        description,
        productType,
        price,
        slashPrice,
        gst,
        withoutGST,
        inStock,
        category,
        specs
      )
    );
  };

  const addFields = () => {
    let newfield = {
      name: "",
      value: "",
    };

    setSpecs([...specs, newfield]);
  };

  return (
    <div className="container-fluid  ps-0">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={4} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          {adminData && adminData.accessLevel === "Viewer" ? (
            <>
              <h1
                className="fw-bold position-absolute"
                style={{
                  fontSize: "3em",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%,50%)",
                }}
              >
                NOT AUTHORISED
              </h1>
            </>
          ) : (
            <div className="mt-3 p-3">
              <div className="align-items-center d-flex justify-content-between pe-3">
                <div>
                  <h2 className="mb-3 fw-bold ms-4">Add Product</h2>
                </div>
                <div>
                  <button
                    className="btn btn-primary fw-bold "
                    style={{
                      letterSpacing: "3px",
                    }}
                    onClick={submitHandler}
                  >
                    Add
                  </button>
                </div>
              </div>
              <hr className="" />
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 p-3">
                    <div
                      className=" bg-light p-3"
                      style={{
                        borderRadius: "10px",
                      }}
                    >
                      <h6 className=" ms-3 mt-4">Product Info</h6>
                      <div className="p-3  mt-2 ">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">Name</label>
                        </div>
                        <div class="form-floating mb-3">
                          <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control"
                            style={{
                              height: "150px",
                            }}
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                          ></textarea>
                          <label for="floatingTextarea2">Description</label>
                        </div>
                        <div class="form-floating mb-3 p-1">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingPassword"
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            placeholder="Password"
                          />
                          <label for="floatingPassword">Tagline</label>
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{ gap: "" }}
                        >
                          <div class="form-floating p-1 w-50 mb-3">
                            <input
                              type="number"
                              class="form-control"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              id="floatingPassword"
                              placeholder="Password"
                            />
                            <label for="floatingPassword">Price</label>
                          </div>
                          <div class="form-floating p-1 w-50 mb-3">
                            <input
                              type="number"
                              value={slashPrice}
                              onChange={(e) => setSlashPrice(e.target.value)}
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                            />
                            <label for="floatingPassword">Slash Price</label>
                          </div>
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{ gap: "" }}
                        >
                          <div class="form-floating p-1 w-50 mb-3">
                            <input
                              type="number"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              value={gst}
                              onChange={(e) => setGst(e.target.value)}
                            />
                            <label for="floatingPassword">GST</label>
                          </div>
                          <div class="form-floating mb-3 p-1 w-50">
                            <input
                              type="number"
                              class="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              value={withoutGST}
                              onChange={(e) => setWithoutGST(e.target.value)}
                            />
                            <label for="floatingPassword">Without GST</label>
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ gap: "" }}
                        >
                          <div class="form-floating mb-3 p-1 w-50">
                            <input
                              type="number"
                              class="form-control"
                              id="floatingPassword"
                              value={inStock}
                              onChange={(e) => setInStock(e.target.value)}
                              placeholder="Password"
                            />
                            <label for="floatingPassword">In Stock</label>
                          </div>
                          <div class="w-50 h-100 pt-1">
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                              class="form-select h-100 mb-3 p-3 "
                              aria-label="Default select example"
                            >
                              <option
                                value="bike"
                                selected={category === "bike" && true}
                              >
                                Bike
                              </option>
                              <option
                                value="accessorie"
                                selected={category === "accessorie" && true}
                              >
                                Accessorie
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 p-3">
                    <div
                      className=" bg-light p-3"
                      style={{
                        borderRadius: "10px",
                      }}
                    >
                      <h6 className=" mt-4 ms-3">Product Specs</h6>
                      <div className=" p-3 mt-2">
                        {specs.map((item, i) => (
                          <>
                            <div className="d-flex">
                              <div className="p-1 w-50">
                                <div class="form-floating mb-3 p-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={item.name}
                                    onChange={(e) => {
                                      let data = [...specs];
                                      data[i].name = e.target.value;
                                      setSpecs(data);
                                    }}
                                    id="floatingPassword"
                                    placeholder="Password"
                                  />
                                  <label for="floatingPassword">Spec</label>
                                </div>
                              </div>
                              <div className="p-1 w-50">
                                <div class="form-floating  mb-3 p-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={item.value}
                                    onChange={(e) => {
                                      let data = [...specs];
                                      data[i].value = e.target.value;
                                      setSpecs(data);
                                    }}
                                    id="floatingPassword"
                                    placeholder="Password"
                                  />
                                  <label for="floatingPassword">Value</label>
                                </div>
                              </div>
                            </div>
                            <hr className="text-light mt-0" />
                          </>
                        ))}
                      </div>

                      <button
                        className="btn mx-auto d-block btn-primary"
                        style={{
                          borderRadius: "50%",
                          height: "40px",
                          width: "40px",
                        }}
                        onClick={addFields}
                      >
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductAddScreen;
