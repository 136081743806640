import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import {
  getAllOrders,
  getAllPaidOrders,
  getAllUnpaidOrders,
} from "../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import exportFromJSON from "export-from-json";

const OrdersScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [paymentStatus, setPaymentStatus] = useState("all");

  const [pageAll, setPageAll] = useState(1);
  const [pagePaid, setPagePaid] = useState(1);
  const [pageUnpaid, setPageUnpaid] = useState(1);

  const [pageCountAll, setPageCountAll] = useState(0);
  const [pageCountPaid, setPageCountPaid] = useState(0);
  const [pageCountUnpaid, setPageCountUnpaid] = useState(0);

  const allOrders = useSelector((state) => state.allOrders);
  const { orders: allOrdersList } = allOrders || {};

  const paidOrders = useSelector((state) => state.paidOrder);
  const { orders: paidOrdersList, pagination: paidOrdersPagination } =
    paidOrders || {};
  const unpaidOrders = useSelector((state) => state.unpaidOrder);
  const { orders: unpaidOrdersList, pagination: unpaidOrdersPagination } =
    unpaidOrders || {};

  // useEffect(() => {
  //   setPageCountAll(allOrdersPagination?.pageCount || 0);
  // }, [allOrdersPagination]);

  useEffect(() => {
    setPageCountPaid(paidOrdersPagination?.pageCount || 0);
  }, [paidOrdersPagination]);

  useEffect(() => {
    setPageCountUnpaid(unpaidOrdersPagination?.pageCount || 0);
  }, [unpaidOrdersPagination]);

  useEffect(() => {
    dispatch(getAllOrders(pageAll, name, from, to));
  }, [dispatch, pageAll, name, from, to]);

  useEffect(() => {
    dispatch(getAllPaidOrders(pagePaid, name, from, to));
  }, [dispatch, pagePaid, name, from, to]);

  useEffect(() => {
    dispatch(getAllUnpaidOrders(pageUnpaid, name, from, to));
  }, [dispatch, pageUnpaid, name, from, to]);
  const searchQuery = () => {
    setPageAll(1);
    setPagePaid(1);
    setPageUnpaid(1);

    dispatch(getAllOrders(1, name, from, to));
    dispatch(getAllPaidOrders(1, name, from, to));
    dispatch(getAllUnpaidOrders(1, name, from, to));
  };

  const prePageAll = () => {
    setPageAll((p) => (p === 1 ? p : p - 1));
  };

  const nextPageAll = () => {
    setPageAll((p) => (p === pageCountAll ? p : p + 1));
  };

  const prePagePaid = () => {
    setPagePaid((p) => (p === 1 ? p : p - 1));
  };

  const nextPagePaid = () => {
    setPagePaid((p) => (p === pageCountPaid ? p : p + 1));
  };

  const prePageUnpaid = () => {
    setPageUnpaid((p) => (p === 1 ? p : p - 1));
  };

  const nextPageUnpaid = () => {
    setPageUnpaid((p) => (p === pageCountUnpaid ? p : p + 1));
  };

  const exportCSV = () => {
    let orders;
    if (paymentStatus === "all") {
      orders = allOrdersList;
    } else if (paymentStatus === "paid") {
      orders = paidOrdersList;
    } else if (paymentStatus === "unpaid") {
      orders = unpaidOrdersList;
    }

    if (orders && orders.length) {
      let data = [];
      for (let i = 0; i < orders.length; i++) {
        data.push({
          orderID: "EM" + String(orders[i]._id).slice(-6).toUpperCase(),
          name: orders[i].name,
          email: orders[i].email,
          phoneNumber: orders[i].phoneNumber,
          createdAt: orders[i].createdAt.slice(0, 10),
          address: `${orders[i].address.addressLine1} ${orders[i].address.addressLine2}`,
          city: orders[i].address.city,
          state: orders[i].address.state,
          pincode: orders[i].address.pincode,
          item: orders[i].items[0].name,
          promocode: orders[i].promocode,
          amount: orders[i].amount,
          paymentStatus: orders[i].paymentStatus,
          gstin: orders[i].gstin,
          orderStatus: orders[i].orderStatus,
          razorpayOrderID: orders[i]?.paymentDetails?.razorpayOrderId,
          razorpayPaymentID: orders[i]?.paymentDetails?.razorpayPaymentId,
        });
      }
      const fileName = "orders";
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data, fileName, exportType });
    }
  };

  return (
    <div className="container-fluid ps-0 ">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={2} />
        </div>
        <div className="col-lg-10 p-0">
          <nav className="w-100 mx-auto">
            <div class="nav nav-tabs mx-auto w-100" id="nav-tab" role="tablist">
              <button
                class="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                onClick={() => setPaymentStatus("all")}
              >
                ALL
              </button>
              <button
                class="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => setPaymentStatus("paid")}
              >
                Paid
              </button>
              <button
                class="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
                onClick={() => setPaymentStatus("unpaid")}
              >
                UnPaid
              </button>
            </div>
          </nav>

          {paymentStatus === "all" && allOrdersList && (
            <div className="mt-3 p-3">
              <div className="d-flex align-items-center justify-content-between pe-3">
                <h4 className="mb-3 fw-bold ">Orders</h4>
                <div className="d-flex  align-items-center">
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingInput"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey ">
                      Name or Mobile Number
                    </label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="date"
                      class="form-control"
                      id="floatingInput"
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey ">From</label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="date"
                      class="form-control"
                      id="floatingInput"
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey">To</label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <button
                      onClick={searchQuery}
                      className="btn  btn-primary text-light"
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <button
                      onClick={exportCSV}
                      className="btn btn-success text-light"
                    >
                      <i class="fa-solid fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="mx-auto">
                  {paymentStatus === "all" && (
                    <div>
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            pageAll === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={prePageAll}
                          >
                            Prev
                          </a>
                        </li>
                        <li
                          className={`page-item ${
                            pageAll === pageCountAll ? "disabled" : ""
                          }`}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={nextPageAll}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <h6 className="m-0 fw-bold pe-5 ">
                  <span className="d-inline-block pe-3">
                    {paymentStatus === "all" &&
                      allOrdersList &&
                      allOrdersList.length}
                  </span>
                  |
                  <span className="d-inline-block ps-3 pe-3">
                    {paymentStatus === "all" &&
                      allOrdersList &&
                      allOrdersList.filter(
                        (order) => order.paymentStatus === true
                      ).length}
                  </span>
                  |
                  <span className="d-inline-block ps-3">
                    {paymentStatus === "all" &&
                      allOrdersList &&
                      allOrdersList.filter(
                        (order) => order.paymentStatus === false
                      ).length}
                  </span>
                </h6>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane  fade show active"
                  id="nav-home"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <table className="  w-100 border-round overflow-hidden">
                    <thead className="position-sticky">
                      <tr
                        className=""
                        style={{
                          top: "0%",
                          position: "sticky",
                        }}
                      >
                        <th className="p-4">Date</th>

                        <th className="p-4">Name</th>
                        <th className="p-4">Email</th>
                        <th className="p-4">Mob. No.</th>

                        <th className="p-4">Items</th>
                        <th className="p-4">Promocode</th>
                        <th className="p-4">Total</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {paymentStatus === "all" &&
                        allOrdersList &&
                        allOrdersList.map((order) => (
                          <tr
                            className={`${
                              order.paymentStatus && "bg-dark text-light"
                            }`}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/orders/${order._id}`)}
                          >
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.createdAt.slice(0, 10)}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">{order.name}</td>
                            <td className="ps-4 pt-3 pb-3 ">{order.email}</td>
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.phoneNumber}
                            </td>
                            <td
                              className="ps-4 pt-3 pb-3 "
                              data-toggle="tooltip"
                              data-placement="top"
                              title={order.items.map((name) => name.name)}
                            >
                              {order.items.reduce(
                                (acc, item) => acc + item.qty,
                                0
                              )}{" "}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.promoCode ? order.promoCode.code : "--"}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">{order.amount}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {paymentStatus === "paid" && paidOrdersList && (
            <div className="mt-3 p-3">
              <div className="d-flex align-items-center justify-content-between pe-3">
                <h4 className="mb-3 fw-bold ">Orders</h4>
                <div className="d-flex  align-items-center">
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingInput"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey ">
                      Name or Mobile Number
                    </label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="date"
                      class="form-control"
                      id="floatingInput"
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey ">From</label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="date"
                      class="form-control"
                      id="floatingInput"
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey">To</label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <button
                      onClick={searchQuery}
                      className="btn  btn-primary text-light"
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <button
                      onClick={exportCSV}
                      className="btn btn-success text-light"
                    >
                      <i class="fa-solid fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="mx-auto">
                  {paymentStatus === "paid" && (
                    <div>
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            pagePaid === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={prePagePaid}
                          >
                            Prev
                          </a>
                        </li>
                        <li
                          className={`page-item ${
                            pagePaid === pageCountPaid ? "disabled" : ""
                          }`}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={nextPagePaid}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <h6 className="m-0 fw-bold pe-5 ">
                  <span className="d-inline-block pe-3">
                    {paymentStatus === "paid" &&
                      paidOrdersList &&
                      paidOrdersList.length}
                  </span>
                </h6>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane  fade show active"
                  id="nav-home"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <table className="  w-100 border-round overflow-hidden">
                    <thead className="position-sticky">
                      <tr
                        className=""
                        style={{
                          top: "0%",
                          position: "sticky",
                        }}
                      >
                        <th className="p-4">Date</th>

                        <th className="p-4">Name</th>
                        <th className="p-4">Email</th>
                        <th className="p-4">Mob. No.</th>

                        <th className="p-4">Items</th>
                        <th className="p-4">Promocode</th>
                        <th className="p-4">Total</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {paymentStatus === "paid" &&
                        paidOrdersList &&
                        paidOrdersList.map((order) => (
                          <tr
                            className={`${
                              order.paymentStatus && "bg-dark text-light"
                            }`}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/orders/${order._id}`)}
                          >
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.createdAt.slice(0, 10)}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">{order.name}</td>
                            <td className="ps-4 pt-3 pb-3 ">{order.email}</td>
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.phoneNumber}
                            </td>
                            <td
                              className="ps-4 pt-3 pb-3 "
                              data-toggle="tooltip"
                              data-placement="top"
                              title={order.items.map((name) => name.name)}
                            >
                              {order.items.reduce(
                                (acc, item) => acc + item.qty,
                                0
                              )}{" "}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.promoCode ? order.promoCode.code : "--"}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">{order.amount}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {paymentStatus === "unpaid" && unpaidOrdersList && (
            <div className="mt-3 p-3">
              <div className="d-flex align-items-center justify-content-between pe-3">
                <h4 className="mb-3 fw-bold ">Orders</h4>
                <div className="d-flex  align-items-center">
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingInput"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey ">
                      Name or Mobile Number
                    </label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="date"
                      class="form-control"
                      id="floatingInput"
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey ">From</label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <input
                      type="date"
                      class="form-control"
                      id="floatingInput"
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput text-grey">To</label>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <button
                      onClick={searchQuery}
                      className="btn  btn-primary text-light"
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                  <div class="form-floating mb-3 me-3">
                    <button
                      onClick={exportCSV}
                      className="btn btn-success text-light"
                    >
                      <i class="fa-solid fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="mx-auto">
                  {paymentStatus === "unpaid" && (
                    <div>
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            pageUnpaid === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={prePageUnpaid}
                          >
                            Prev
                          </a>
                        </li>
                        <li
                          className={`page-item ${
                            pageUnpaid === pageCountUnpaid ? "disabled" : ""
                          }`}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={nextPageUnpaid}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <h6 className="m-0 fw-bold pe-5 ">
                  <span className="d-inline-block pe-3">
                    {paymentStatus === "unpaid" &&
                      unpaidOrdersList &&
                      unpaidOrdersList.length}
                  </span>
                </h6>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane  fade show active"
                  id="nav-home"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <table className="  w-100 border-round overflow-hidden">
                    <thead className="position-sticky">
                      <tr
                        className=""
                        style={{
                          top: "0%",
                          position: "sticky",
                        }}
                      >
                        <th className="p-4">Date</th>

                        <th className="p-4">Name</th>
                        <th className="p-4">Email</th>
                        <th className="p-4">Mob. No.</th>

                        <th className="p-4">Items</th>
                        <th className="p-4">Promocode</th>
                        <th className="p-4">Total</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {paymentStatus === "unpaid" &&
                        unpaidOrdersList &&
                        unpaidOrdersList.map((order) => (
                          <tr
                            className={`${
                              order.paymentStatus && "bg-dark text-light"
                            }`}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => navigate(`/orders/${order._id}`)}
                          >
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.createdAt.slice(0, 10)}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">{order.name}</td>
                            <td className="ps-4 pt-3 pb-3 ">{order.email}</td>
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.phoneNumber}
                            </td>
                            <td
                              className="ps-4 pt-3 pb-3 "
                              data-toggle="tooltip"
                              data-placement="top"
                              title={order.items.map((name) => name.name)}
                            >
                              {order.items.reduce(
                                (acc, item) => acc + item.qty,
                                0
                              )}{" "}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">
                              {order.promoCode ? order.promoCode.code : "--"}
                            </td>
                            <td className="ps-4 pt-3 pb-3 ">{order.amount}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {paymentStatus === "all" && !allOrdersList && <Loader />}
          {paymentStatus === "paid" && !paidOrdersList && <Loader />}
          {paymentStatus === "unpaid" && !unpaidOrdersList && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default OrdersScreen;
