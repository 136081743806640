import DashboardScreen from "./screens/DashboardScreen";
import TestRideScreen from "./screens/TestRideScreen";

import PromoCodeScreen from "./screens/PromoCodeScreen";
import WarrantyScreen from "./screens/WarrantyScreen";
import PartnerWithUsScreen from "./screens/PartnerWithUsScreen";
import ProductsScreen from "./screens/ProductsScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import InsuranceScreen from "./screens/InsuranceScreen";

import LoginScreen from "./screens/LoginScreen";
import OrdersScreen from "./screens/OrdersScreen";
import OrderScreen from "./screens/OrderScreen";
import ProductScreen from "./screens/ProductScreen";
import PrebookingScreen from "./screens/PrebookingScreen";
import NotifyMeScreen from "./screens/NotifyMeScreen";
import PrebookingDetailsScreen from "./screens/PrebookingDetailsScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductAddScreen from "./screens/ProductAddScreen";
import Stores from "./screens/Stores";
import AddStoreScreen from "./screens/AddStore";
import StoresScreen from "./screens/Stores";
import StoreScreen from "./screens/StoreScreen";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardScreen />} />
        <Route path="/product" element={<ProductsScreen />} />
        <Route path="/product/:id" element={<ProductScreen />} />
        <Route path="/addproduct" element={<ProductAddScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/warranty" element={<WarrantyScreen />} />
        <Route path="/testride" element={<TestRideScreen />} />
        <Route path="/prebookings" element={<PrebookingScreen />}></Route>
        <Route
          path="/prebookings/:id"
          element={<PrebookingDetailsScreen />}
        ></Route>

        <Route path="/orders" element={<OrdersScreen />}></Route>
        <Route path="/notifyme" element={<NotifyMeScreen />}></Route>
        <Route path="/orders/:id" element={<OrderScreen />}></Route>

        <Route path="partner-with-us" element={<PartnerWithUsScreen />} />

        {/* <Route path="/admins" element={<AdminListScreen />}></Route> */}
        <Route path="/store" element={<StoresScreen />} />
        <Route path="/store/:id" element={<StoreScreen />} />
        <Route path="/add-store" element={<AddStoreScreen />} />

        <Route path="/insurance" element={<InsuranceScreen />} />
        <Route path="/contact" element={<ContactUsScreen />} />

        <Route path="/promocode" element={<PromoCodeScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
