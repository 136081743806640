import {
  GET_ALL_ORDERS_FAIL,
  GET_ALL_ORDERS_REQUEST,
  GET_ALL_ORDERS_SUCCESS,
  GET_ORDER_BY_ID_FAIL,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ALL_PAID_ORDERS_REQUEST,
  GET_ALL_PAID_ORDERS_SUCCESS,
  GET_ALL_PAID_ORDERS_FAIL,
  GET_ALL_UNPAID_ORDERS_REQUEST,
  GET_ALL_UNPAID_ORDERS_SUCCESS,
  GET_ALL_UNPAID_ORDERS_FAIL,
  GET_ALL_ORDERS_DASHBOARD_SUCCESS
} from "../constants/orderConstants";

export const allOrdersReducer = (
  state = { orders: [], pagination: undefined },
  action
) => {
  switch (action.type) {
    case GET_ALL_ORDERS_REQUEST:
      return { loading: true };
    case GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        pagination: action.payload.pagination,
      };
    case GET_ALL_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_BY_ID_REQUEST:
      return { loading: true };
    case GET_ORDER_BY_ID_SUCCESS:
      return { loading: false, order: action.payload };
    case GET_ORDER_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllOrdersDashboard = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS_DASHBOARD_SUCCESS:
      return { loading: false, order: action.payload };
    default:
      return state;
  }
};




export const allPaidOrdersReducer = (
  state = { orders: [], pagination: undefined },
  action
) => {
  switch (action.type) {
    case GET_ALL_PAID_ORDERS_REQUEST:
      return { loading: true };
    case GET_ALL_PAID_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        pagination: action.payload.pagination,
      };
    case GET_ALL_PAID_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allUnpaidOrdersReducer = (
  state = { orders: [], pagination: undefined },
  action
) => {
  switch (action.type) {
    case GET_ALL_UNPAID_ORDERS_REQUEST:
      return { loading: true };
    case GET_ALL_UNPAID_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        pagination: action.payload.pagination,
      };
    case GET_ALL_UNPAID_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

