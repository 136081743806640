import {GET_PROMOCODE_REQUEST,GET_PROMOCODE_SUCCESS,GET_PROMOCODE_FAIL} from '../constants/promocodeConstants'

export const getPromocodeReducer = (state = {}, action)=>{
    switch (action.type) {
        case GET_PROMOCODE_REQUEST:
            return {loading : true} 
        case GET_PROMOCODE_SUCCESS:
            return { loading : false, promocode:action.payload}  
        case GET_PROMOCODE_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}
