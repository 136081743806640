import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import "../css/dealer.css";
import { createStore } from "../actions/storeActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddStoreScreen = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [storeId, setStoreId] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [link, setLink] = useState("");
  const [hours, setHours] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [ebo, setEBO] = useState("false");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = dispatch(
      createStore(
        name,
        phoneNumber,
        storeId,
        address,
        city,
        state,
        link,
        hours,
        latitude,
        longitude,
        ebo
      )
    );
    if (result) {
      alert("Store Added Successfully");
      navigate("/store");
    }
  };
  return (
    <div className="container-fluid ">
      <div className="row ">
        <div className="col-lg-2 p-0">
          <Sidebar active={6} />
        </div>
        <div className="col-lg-10 p-0">
          <form onSubmit={handleSubmit}>
            <div className="mt-3 p-3">
              <div className="d-flex align-items-center justify-content-between p-5 pt-3 ps-0 ">
                <h1 className="mb-3 fw-bold">Add Store</h1>

                <button
                  type="submit"
                  style={{ width: "20%" }}
                  className="btn btn-primary mb-3 mt-3  text-light"
                >
                  Save
                </button>
              </div>
              <div>
                <div className="row p-5 pt-3 ps-0 ">
                  <div
                    className="col-6 p-4 pt-1"
                    style={{ borderRight: "2px solid grey" }}
                  >
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <label for="floatingInput">Name</label>
                    </div>
                    <div className="d-flex  ">
                      <div className="form-floating col-6 mb-3 me-1">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder="Store ID"
                          value={storeId}
                          onChange={(e) => setStoreId(e.target.value)}
                        />
                        <label for="floatingInput">Store ID</label>
                      </div>
                      <div className="form-floating col-6  mb-3">
                        <input
                          type="tel"
                          class="form-control"
                          placeholder="Mobile Number"
                          required
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <label for="floatingInput">Mobile Number</label>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Address"
                        required
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <label for="floatingInput">Address</label>
                    </div>
                  </div>
                  <div className="col-6 pt-1 p-4">
                    <div className="d-flex">
                      <div className="form-floating mb-3 me-1 w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          required
                          placeholder=""
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                        <label for="floatingInput">City</label>
                      </div>
                      <div className="form-floating me-1 w-100">
                        <select
                          class="form-control"
                          required
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={(e) => setState(e.target.value)}
                        >
                          <option selected value="">
                            India
                          </option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Dadra & Nagar Haveli">
                            Dadra & Nagar Haveli
                          </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jammu & Kashmir">
                            Jammu & Kashmir
                          </option>
                          <option value="Jharkhand">Jharkhand</option>

                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>

                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Orissa">Orissa</option>
                          <option value="Puducherry">Puducherry</option>
                          <option value="Punjab">Punjab</option>

                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>

                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                        </select>
                        <label for="floatingSelect" className="text-grey">
                          State
                        </label>
                      </div>
                      {/* <div className="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                      />
                      <label for="floatingInput">Hours</label>
                    </div> */}
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      // style={{ gap: "1" }}
                    >
                      <div className="form-floating mb-3 w-50 me-2">
                        <input
                          type="url"
                          class="form-control"
                          required
                          id="floatingInput"
                          placeholder=""
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                        />
                        <label for="floatingInput">Google Maps Link</label>
                      </div>
                      <div class="form-check w-50">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="true"
                          onChange={(e) =>
                            setEBO(e.target.checked ? "true" : "false")
                          }
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          EBO
                        </label>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="form-floating mb-3 me-1 w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          required
                          placeholder="10:00AM to 08:00PM"
                          value={hours}
                          onChange={(e) => setHours(e.target.value)}
                        />
                        <label for="floatingInput">Hours</label>
                      </div>
                      <div className="form-floating mb-3 me-1 w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          required
                          placeholder=""
                          value={latitude}
                          onChange={(e) => setLatitude(e.target.value)}
                        />
                        <label for="floatingInput">Latitude</label>
                      </div>
                      <div className="form-floating mb-3 me-1 w-100">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          required
                          placeholder=""
                          value={longitude}
                          onChange={(e) => setLongitude(e.target.value)}
                        />
                        <label for="floatingInput">Longitude</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddStoreScreen;
